@import "~@angular/material/theming";
$palette-primary: mat-palette($mat-blue-grey,100, 500, A100);
$palette-accent: mat-palette($mat-green, 100, 500, A100);
$palette-warn: mat-palette($mat-red);
$theme: mat-dark-theme($palette-primary, $palette-accent, $palette-warn);
$primary: map-get($theme, primary);
$accent: map-get($theme,accent);

$btn-fg-colour: #111;
$btn-fg-active-colour: #333;

$table-border: #444;

$base-fg-colour-dark: #000;
$base-fg-colour-light: #fff;

$base-colour-dark: #222;
$base-colour-light: #f6f6f6;

$rag-green-colour: #69BC45 ;
$rag-amber-colour:#E3943C;
$rag-blue-colour: #4877AA;
$rag-red-colour:#BB3E2D;

$warning-colour: #f78b33;

$readiness-blue: #20303C ;
$readiness-green: #69BC45 ;
$readiness-red: #ff2222;
$readiness-green-dark: darken($readiness-green,20%);
$readiness-green-light: rgb(37, 168, 65);
$readiness-yellow: rgb(230,200,120);

$readiness-grey-m-0: #606060;
$readiness-grey-m-1:#404040;

$readiness-grey-0: #a0a0a0;
$readiness-grey-1: #CBCBCB;
$readiness-grey-2: #DEDEDE;
$readiness-grey-3: #EEEEEE;
$readiness-grey-4: #F5F5F5;

$readiness-error: #c73021;
$readiness-error-dark: darken(#c73021,30%);
$readiness-text-colour: #344D60;
$readiness-loading-colour: #98732d;

$readiness-green-lt20: lighten($readiness-green, 20%);

$primary-50-color: mat-color($primary, 50);
$primary-100-color: mat-color($primary, 100);
$primary-200-color: mat-color($primary, 200);
$primary-300-color: mat-color($primary, 300);
$primary-400-color: mat-color($primary, 400);
$primary-500-color: mat-color($primary, 500);
$primary-600-color: mat-color($primary, 600);
$primary-700-color: mat-color($primary, 700);
$primary-800-color: mat-color($primary, 800);
$primary-900-color: mat-color($primary, 900);
$primary-A400-color: mat-color($primary, A400);

$accent-50-color: mat-color($accent, 50);
$accent-100-color: mat-color($accent, 100);
$accent-200-color: mat-color($accent, 200);
$accent-300-color: mat-color($accent, 300);
$accent-400-color: mat-color($accent, 400);
$accent-500-color: mat-color($accent, 500);
$accent-600-color: mat-color($accent, 600);
$accent-700-color: mat-color($accent, 700);
$accent-800-color: mat-color($accent, 800);
$accent-900-color: mat-color($accent, 900);
$accent-A400-color: mat-color($accent, A400);

$primary-800-color-dk10: darken(mat-color($primary, 800), 10%);
$primary-800-color-lt10: lighten(mat-color($primary, 800), 10%);
$primary-100-color-lt10: lighten(mat-color($primary, 100), 10%);
$primary-900-color-dk20: darken(mat-color($primary, 900),20%);

