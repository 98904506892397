@use 'colours';

.sw-viewcontainer-collapsed .sw-table-collapsable thead {
    display: none;
}

.sw-viewcontainer-collapsed .sw-table-collapsablecolumn, .sw-table-hidden-column {
    display: none;
}

.sw-table td {
    padding:3px;
    border-bottom: solid 1px colours.$readiness-grey-1;
}

.sw-table tr {
    vertical-align: top;
    cursor:pointer;
}

.sw-table tr.sw-activerow {
    background: colours.$accent-400-color;
    color: colours.$base-colour-light;
}

.sw-table td.sw-right-pad {
    padding-right:10px;
}

.sw-table-disabled td {
    padding:3px;
    border-bottom: solid 1px colours.$readiness-grey-1;
}

.sw-table-disabled tr {
    vertical-align: top;
}

.sw-table-disabled tr.sw-activerow {
    background: colours.$readiness-blue;
    color: colours.$base-colour-light;
}

.sw-table td.sw-right-pad {
    padding-right:10px;
}

.sw-grid-align-centre {
    text-align: center;
}

.sw-oddrow {
    background-color:#f8f8f8;
}

.sw-pointer {
    cursor: pointer ;
}

.slickgrid-container .grid-canvas .slick-cell{
    font-family: Ubuntu;
    font-size:14px !important;
}

.slickgrid-container .slick-header-columns .slick-header-column.ui-state-default {
    font-family: Ubuntu;
    font-size:14px !important;
    font-weight: 500;
}

.slickgrid-container .grid-canvas .slick-row.active { //Stops horiz scroll bar in slick grids
    padding: 0px;
}

.sw-table-collapsable {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
    font-size: 10pt;
    font-family: "Ubuntu";
}

.sw-table-collapsable > thead > tr {
    border-bottom: 1px solid colours.$table-border;
}

.sw-table-collapsable th {
    text-align: left;
}

.sw-table-collapsable th,
.sw-table-collapsable td {
    padding: 4px 10px;
}

.sw-table-collapsable tbody tr:hover, .sw-table-activerow:hover, .sw-table-hover:hover {
    background: colours.$readiness-blue;
    color: colours.$base-colour-light;
    cursor: pointer;
}

.sw-viewcontainer-collapsed {
    max-width: fit-content;
}

.sw-table-collapsable tbody tr.sw-table-activerow, .sw-table-activerow {    
    background: colours.$readiness-blue;
    color: colours.$base-colour-light;
    outline: none;
}

.sw-table-collapsable tbody .sw-table-activerow:hover, .sw-table-activerow:hover, .sw-table-hover:hover {
    background: colours.$readiness-blue;
    color: colours.$base-colour-light;
}

td.sw-column-top {
    vertical-align: top;
}

.table-filter-toggle {
    border-top:solid 2px colours.$readiness-error-dark;
    border-bottom:none;
    cursor:pointer;
}

.ui-grid-row-selected a {
    color: colours.$base-fg-colour-light;
}

.sw-simple-table thead th {
    text-align:left;
    padding:10px;
    background-color: colours.$readiness-grey-3;
}

.no-side-border{
    border-right: none;
}
