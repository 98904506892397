@use './app/shared/css/colours';

html, body { height: 100%; }
body { 
    margin: 0; 
}

th {
    text-align: left;
  }

.sw-bold {
    font-weight: bold !important;
}

.sw-upper {
    text-transform:uppercase;
}

.sw-ok {
    color: colours.$readiness-green;
}

.sw-err {
    color:colours.$readiness-red;
}

.sw-warning {
    color: colours.$warning-colour;
}

.sw-heading h2 {
    margin-bottom:0px;
}

.sw-pad-small {
    padding:5px;
}

.sw-pad {
    padding:10px;
}

.sw-big-pad {
    padding:25px;
}

.sw-pad-top-small {
    padding-top:5px;
}

.sw-pad-top {
    padding-top:10px;
}

.sw-big-pad-top {
    padding-top:25px;
}

.sw-pad-left-small {
    padding-left:5px;
}

.sw-big-pad-left {
    padding-left:25px;
}

.sw-pad-right {
    padding-right:10px;
}

.sw-pad-right-small {
    padding-right:5px;
}

.sw-pad-bottom {
    padding-bottom:10px;
}

.sw-pad-horiz {
    padding-left:10px;
    padding-right:10px;
}

.full-width {
    width:100%;
}

.full-width-bs {
    width:100%;
    box-sizing: border-box;
}

.full-height {
    height: 100%;
}

.full-height-bs {
    height:100%;
    box-sizing: border-box;
}

.full-height-scroll {
    height:100%;
    overflow: auto;
}

.sw-small-icon-button {
    line-height:normal !important;
    height:26px !important;
}

.sw-segment {
    position:relative;
}

.sw-segment > * {
    position: absolute;
    left:0;
    right:0;
    top:0px;
    bottom:0px;
    overflow: auto;
}

.sw-error {
    color: #c73021;
    font-weight: bold;
}
  
.sw-error-no-bold {
    color: #c73021;
}
  
.sw-errordark {
    color: #44100b;
    font-weight: bold;
}

.sw-grid-input-filter {
    width:100%;
    border-radius: 4px;
    border-top:solid 2px rgb(170,170,170);
    border-left:solid 2px  rgb(170,170,170);
    border-right:solid 2px  rgb(170,170,170);
    border-bottom:solid 3px  rgb(170,170,170);
}

.sw-grid-cell-middle {
    text-align: center;
}


/* Used by package browse functionality - needed to apply globally from cell template */

.msi-row {
    color: #44100b;
}

.msi-row-required {
    color:#062;
}
.msi-row-required-issues {
    color: crimson;
}
.msi-row-issues {
    color:colours.$readiness-error;
}
.msi-row-embellishment {
    color:colours.$readiness-grey-0;;
}

/* end of package browse styling */

@import './app/shared/css/forms.scss';
@import './app/shared/css/buttons.scss';
@import './app/shared/css/tables.scss';
@import './app/shared/rag-column/rag-column.scss'; // app grid rag column needs this
