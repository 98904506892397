@use 'fonts';
@use 'colours';

.sw-button-default {
    text-transform: uppercase;
    line-height: 24px !important;
    font-size:fonts.$font-normal-m1 !important;
}

.sw-button-link {
    text-transform: uppercase;
    line-height: 20px !important;
    font-size: fonts.$font-normal-m1 !important;
    min-width: 30px;
    padding:0px !important;
}

.sw-tree-button {
    line-height: 20px !important;
    font-size: fonts.$font-normal-m1 !important;
    min-width: 30px;
    padding:0px 6px !important;
}

.sw-tree-button span {
    font-size:12pt;
}

.sw-tree-button div mat-icon {
    color:colours.$accent-400-color;
}

.mat-button-toggle-label-content {
    font-size:fonts.$font-normal;
    color:colours.$readiness-grey-0;
    line-height: 32px !important;
}

@keyframes blink {
  0% { background: transparent }
  33% { background: linear-gradient(90deg, rgba(colours.$primary-200-color, 0.4) 3%, transparent 5%); }
  66% { background: linear-gradient(90deg, rgba(colours.$primary-200-color, 0.4) 5%, transparent 7%); }
  100% { background: linear-gradient(90deg, rgba(colours.$primary-200-color, 0.4) 2%, transparent 4%); }
}

.sw-progress-pending {
  animation: blink 2s infinite;
}

.sw-progress-10 {
    background: linear-gradient(90deg, rgba(colours.$primary-400-color, 0.4) 10%, transparent 12%);
  }
  
  .sw-progress-20 {
    background: linear-gradient(90deg, rgba(colours.$primary-400-color, 0.4) 20%, transparent 22%);
  }
  
  .sw-progress-30 {
    background: linear-gradient(90deg, rgba(colours.$primary-400-color, 0.4) 30%, transparent 32%);
  }
  
  .sw-progress-40 {
    background: linear-gradient(90deg, rgba(colours.$primary-400-color, 0.4) 40%, transparent 42%);
  }
  
  .sw-progress-50 {
    background: linear-gradient(90deg, rgba(colours.$primary-400-color, 0.4) 50%, transparent 52%);
  }
  
  .sw-progress-60 {
    background: linear-gradient(90deg, rgba(colours.$primary-400-color, 0.4) 60%, transparent 62%);
  }
  
  .sw-progress-70 {
    background: linear-gradient(90deg, rgba(colours.$primary-400-color, 0.4) 70%, transparent 72%);
  }
  
  .sw-progress-80 {
    background: linear-gradient(90deg, rgba(colours.$primary-400-color, 0.4) 80%, transparent 82%);
  }
  
  .sw-progress-90 {
    background: linear-gradient(90deg, rgba(colours.$primary-400-color, 0.4) 90%, transparent 92%);
  }
  
  .sw-progress-x-10 {
    background: linear-gradient(90deg, #3f7229 10%, transparent 12%);
    color: #a0a0a0;
  }
  
  .sw-progress-x-20 {
    background: linear-gradient(90deg, #3f7229 20%, transparent 22%);
    color: #a0a0a0;
  }
  
  .sw-progress-x-30 {
    background: linear-gradient(90deg, #3f7229 30%, transparent 32%);
    color: #a0a0a0;
  }
  
  .sw-progress-x-40 {
    background: linear-gradient(90deg, #3f7229 40%, transparent 42%);
    color: #a0a0a0;
  }
  
  .sw-progress-x-50 {
    background: linear-gradient(90deg, #3f7229 50%, transparent 52%);
    color: #a0a0a0;
  }
  
  .sw-progress-x-60 {
    background: linear-gradient(90deg, #3f7229 60%, transparent 62%);
    color: #a0a0a0;
  }
  
  .sw-progress-x-70 {
    background: linear-gradient(90deg, #3f7229 70%, transparent 72%);
    color: #a0a0a0;
  }
  
  .sw-progress-x-80 {
    background: linear-gradient(90deg, #3f7229 80%, transparent 82%);
    color: #a0a0a0;
  }
  
  .sw-progress-x-90 {
    background: linear-gradient(90deg, #3f7229 90%, transparent 92%);
    color: #a0a0a0;
  }
  
  .sw-incomplete {
    color:colours.$readiness-grey-0;
  }