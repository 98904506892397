@import '~@angular/material/theming';
@include mat-core();

$custom-typography: mat-typography-config(
    $font-family: '"Ubuntu", sans-serif;',
    $body-1: mat-typography-level(16px, 22px, 500),
    $title: mat-typography-level(22px, 26px, 800)
);
@include mat-core($custom-typography);

/* ======== Angular material custom themes ======== */ 
$palette-primary: mat-palette($mat-blue,700);
$palette-accent: mat-palette($mat-pink, 100, 500, A100);
$palette-warn: mat-palette($mat-red);

$q-theme: mat-light-theme($palette-primary, $palette-accent, $palette-warn);

@include angular-material-theme($q-theme);

.mat-menu-item {
    font-family: 'Ubuntu';
    font-size:12px !important;
    line-height: 32px;
    height:32px !important;
}

$primary: map-get($q-theme, primary);
$accent: map-get($q-theme, accent);


