@use 'colours';
@use 'fonts';

fieldset {
    border-radius: 10px;
    border:solid 1px colours.$readiness-grey-3;
    min-height: 62px;
    margin-bottom:10px;
}

legend {
    font-size:fonts.$font-normal-m2;
    color: colours.$readiness-grey-1;
    margin-left:-5px;
    margin-right:10px;
}

.mat-checkbox-ripple .mat-ripple-element, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: colours.$accent-400-color !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: colours.$accent-400-color !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(colours.$accent-400-color, 0.54) !important;
}

.sw-input-container {
    border-bottom-width: 1px;
    border-bottom-color: colours.$readiness-grey-0;
    border-bottom-style: dotted;
    margin-bottom:8px;
    padding-bottom:8px;
}

.sw-input-container > div > label {
    display:block;
    font-weight: normal;
    font-size:13px;
    padding-bottom:6px;
}

.sw-input-container > div > span {
    font-weight: normal;
    color: colours.$readiness-grey-0;
    font-size:14px;
}

.sw-input-container > div {
   padding-bottom:5px;
}

.sw-error-container {
    text-align: center;
    color:colours.$base-fg-colour-light;
    background-color: colours.$readiness-error;
    border:solid 1px darken(colours.$readiness-error,20%);
    position: relative;
    top:-3px;
    line-height: 22px;
}

.sw-title {
    font-weight: 600;
    font-size:15px;
    text-transform: uppercase;
}

.sw-subtext {
    color:colours.$readiness-grey-0;
    font-size:fonts.$font-normal;
}

.sw-server {
    font-weight: 600;
    font-size: 15px;
    text-transform: uppercase;
    padding-left:10px;
    padding-right:10px;
    border-right: solid 3px colours.$readiness-grey-0;
}