@use '../../shared/css/colours';

.sw-rag {
    display: block;
    border-radius: 2px;
    width: 30px;
    height: 100%;
    color: #fff;
    font-size: 9pt;
    height: 20px;
    text-align: center;
  }
  
  .sw-rag-small {
    display: block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    box-shadow: 1px 1px 1px 1px #a0a0a0;
  }
  
  .sw-rag-small.red {
    background-color: colours.$rag-red-colour;
  }
  
  .sw-rag-small.amber {
    background-color: colours.$rag-amber-colour;
  }
  
  .sw-rag-small.green {
    background-color: colours.$rag-green-colour;
  }
  
  .sw-rag-small.blue {
    background-color: colours.$rag-blue-colour;
  }
  
  .sw-rag-wide {
    width: 35px;
  }
  
  .sw-rag-code {
    position: relative;
    top: 3px;
    width: 100%;
    color: #fff;
  }
  
  .sw-rag-red {
    background-color: colours.$rag-red-colour;
  }
  
  .sw-rag-amber {
    background-color: colours.$rag-amber-colour;
  }
  
  .sw-rag-green {
    background-color: colours.$rag-green-colour;
  }
  
  .sw-rag-blue {
    background-color: colours.$rag-blue-colour;
  }
  
  .sw-rag-noAssess {
    background-color: #424242;
    background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, rgba(255, 255, 255, 0.5) 35px, rgba(255, 255, 255, 0.5) 70px);
  }
  